/**
 * `Preview` is an interstitial page component that displays while preview data
 * is fetched from Prismic. When the data is loaded, the component navigates
 * to the appropriate page based on the path mappings in `linkResolver`.
 *
 */

import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { navigate } from "gatsby";
import { usePrismicPreview } from "gatsby-source-prismic";
import { linkResolver } from "@dataResolvers";
import { Text } from "@atoms";
import { LogoMarkLight } from "@svg";

/* eslint react/prop-types: 0 */

const Preview = () => {
  const [, dispatch] = useAppState();

  const { previewData, path } = usePrismicPreview({
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
  });

  useEffect(() => {
    if (previewData && path) {
      // set previewData to window
      window.__PRISMIC_PREVIEW_DATA__ = previewData;

      // toggle previewMode state
      dispatch({
        type: "setPreviewMode",
        previewMode: true,
      });

      // navigate to the path that is being previewed
      navigate(path);
    }
  }, [previewData, path]);

  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-green-2 z-50">
      <div style={{ width: 73, height: 40 }}>
        <LogoMarkLight />
      </div>

      <Text tag="label" className="text-white mt-4">
        Loading preview
      </Text>
    </div>
  );
};

export default Preview;
